export default {
	state: () => ({
		orders					: [],
		order					: undefined,
		preorders				: [],
		listBuyAgain			: [],
		checkoutOrder			: null,
		validCheckout   		: null,
		shippingData			: null,
		draftOrder				: null,
		statusPay				: null,
		statusPayBalance		: null,
		transactions			: [],
		url_download_cart_file	: ""
	}),
	mutations: {
		setOrders(state, payload) {
			state.orders = payload
		},
		setOrder(state, payload) {
			state.order = payload
		},
		setPreorders(state, payload) {
			state.preorders = payload
		},
		setBuyAgainProducts: (state, payload) =>  {
			state.listBuyAgain = payload
		},
		setCheckoutOrder(state, payload) {
			state.checkoutOrder = payload
		},
		setValidCheckout(state, payload) {
			state.validCheckout = payload;
		},
		setStatusPay(state, payload) {
			state.statusPay = payload
		},
		setStatusPayBalance(state, payload) {
			state.statusPayBalance = payload
		},
		setTransactions(state, payload) {
			state.transactions = payload
		},
		setDownloadTransactionsFile(state, payload) {
			state.url_download_cart_file = payload;
		},
		setShippingData(state, payload) {
			state.shippingData = payload
		},
	},
	getters: {
		getterOrder						: state => state.order,
		getterOrders					: state => state.orders,
		getterPreorders					: state => state.preorders,
		getterListBuyAgain				: state => state.listBuyAgain,
		getterCheckoutOrder				: state => state.checkoutOrder,
		getterValidCheckout				: state => state.validCheckout,
		getterStatusPay					: state => state.statusPay,
		getterStatusPayBalance			: state => state.statusPayBalance,
		getterTransactions				: state => state.transactions,
		getterShippingData				: state => state.shippingData,
		getterDownloadTransactionsFile	: state => state.url_download_cart_file,
	},
	actions: {
		// =================================================================
		//  ORDER ACTIONS
		// =================================================================
		async getOrder({commit}, payload) {
			
            let request_body ={
                fields: {
					name: 1, 
					quantity_items: 1, 
					total_line_items_price: 1,
					total_discounts: 1,
					total_tax: 1,
					total_shipping_price: 1,
					total_price: 1, 
					financial_status: 1, 
					shipping_lines: 1,
					shipping_address: 1,
					fulfillments: 1,
					note: 1,
					created_at: 1, 
				},
                options: {
					populate: [
						{ 
							path: 'customer',
							select: 'first_name last_name email phone'
						},
						{
							path: 'line_items',
							select: 'shopify_id sku quantity price total_discount'
						}
					]
                }
            };
			await this.$hefesto.$post(`/orders/find/${ payload.name }`, request_body).then( (axios_response) => {
				
				commit('setOrder', axios_response.body);
			}).catch( (axios_error) => {
				
				commit('setOrder', null);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getOrders({commit}, payload) {
			
            let request_body ={
                fields: {
					created_at: 1, 
					name: 1, 
					quantity_items: 1, 
					total_price: 1, 
					financial_status: 1, 
					fulfillments: 1,
					cancelled_at: 1
				},
                options: {
                    paginated: {
                        page: payload.page,
                        per_page: 0,
                    },
                    sort: { order_number: 1 },
					populate: null
                },
				search: payload.search,
				min_date: payload.min_date,
				max_date: payload.max_date
            };
			await this.$hefesto.$post(`/orders/list`, request_body).then( (axios_response) => {
				
				commit('setOrders', axios_response.body.documents);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async createOrder({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/valid-checkout`, { checkout: payload.body_checkout_request, draft_order: payload.body_draft_order_request }).then( async (axios_response) => {
				
				payload.body_draft_order_request.db_draft_id 		= axios_response.body.draft_order.db_draft_id;
				payload.body_draft_order_request.shopify_draft_id 	= axios_response.body.draft_order.shopify_draft_id;
				payload.body_draft_order_request.note_attributes 	= axios_response.body.draft_order.note_attributes;
				await this.$hefesto.$post('/orders/create', payload.body_draft_order_request).then( async (axios_order_response) => {
						
					commit( 'setCheckoutOrder', { valid: true, data: axios_order_response.body } );
				}).catch( (axios_error) => {
					
					const  { body, title, message } = axios_error.response.data;
					
					if( data.status != "order-not-created" ){
						
						commit( 'setCheckoutOrder', { valid: true, data: body } );
					}
					else{
						
						commit( 'setCheckoutOrder', { valid: false, data: { title: title, message: message } } );
						commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
					}
				});
			}).catch( (axios_error) => {
				
				commit('setValidCheckout', false);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data, isCheckout: true });
			})
		},
		async payOrder({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/valid-checkout`, { checkout: payload.body_checkout_request, draft_order: payload.body_draft_order_request }).then( async (axios_response) => {
				
				payload.body_payment_request.db_draft_id 	= axios_response.body.draft_order.db_draft_id;
				payload.body_payment_request.draft_id 		= axios_response.body.draft_order.shopify_draft_id;
				payload.body_payment_request.note_attributes= axios_response.body.draft_order.note_attributes;
				payload.body_payment_request.user_id 		= axios_response.body.draft_order.user_id;
				payload.body_payment_request.customer_id 	= axios_response.body.draft_order.customer_id;

				await this.$billing.$post(`/payment/pay-order/${ payload.body_payment_request.customer_id }`, payload.body_payment_request).then( (axios_billing_response) => {
							
					if( axios_billing_response.result === "approved"){
						
						commit('setStatusPay', { valid: true, data: axios_billing_response });
					}
					else{
						
						commit('setStatusPay', { valid: false, data: { title: "Error: Pay Order", message: axios_billing_response.message } });
						commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: axios_billing_response.message, app_version: this.$cookies.get('app-version') }, isCheckout: true });
					}
				}).catch( (axios_error) => {
					
					commit('setStatusPay', { valid: false, data: { title: "Error: Pay Order", message: axios_error.response.data.message } });
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Order", message: axios_error.response.data.message, app_version: this.$cookies.get('app-version') }, isCheckout: true });
				});
			}).catch( (axios_error) => {
				
				commit('setValidCheckout', false);
				if( axios_error.response.status != 403 ){
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				}
			})
		},
		async payBalance({commit}, payload) {
			
			await this.$billing.$post(`/payment/pay-order/${ payload.payment.customer_id }`, payload.payment).then( (axios_response) => {
				
				commit('setStatusPayBalance', axios_response);
				
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: "Error: Pay Balance", message: axios_error.response.data.message, app_version: this.$cookies.get('app-version') } });
			});
		},
		// =================================================================
		//  PREORDERS ACTIONS
		// =================================================================
		async listProductPreorders({commit}, payload) {
			await this.$hefesto.$get(`/preorders/get-products`).then( (axios_response) => {
				
				commit( 'setPreorders', axios_response.body );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  BUYAGAIN ACTIONS
		// =================================================================
		async listBuyAgainProducts({commit}, payload) {
			
			await this.$hefesto.$get(`/buy-again/list?sort=last_purcharse&sort_direction=desc`).then( (axios_response) => {
				
				commit('setBuyAgainProducts', axios_response.body);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  CHECKOUT ACTIONS
		// =================================================================
		async resetShippingData({commit}, payload) {
			
			commit('setShippingData', payload);
		},
		async calculateShipping({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/shipping-options?product_origin=${ payload.product_origin }`,  payload ).then( (axios_response) => {
				
				commit('setShippingData', axios_response.body.shipping_info);
				commit('setCoupon', axios_response.body.coupon);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  TRANSACTION ACTIONS
		// =================================================================
		async getTransactions({commit}, payload) {
			
			await this.$hefesto.$get(`/app-billing/transactions?page=${ payload.page }&search=${ encodeURI(payload.search) }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (axios_response) => {
				
				commit( 'setTransactions', axios_response.body );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async downloadExportTransactionsFile({commit}, payload) {
			
			await this.$hefesto.$get(`/orders/transactions/download-file?page=${ payload.page }&from=${ payload.min_date }&to=${ payload.max_date }`).then( (axios_response) => {
				
				commit('setDownloadTransactionsFile', "");
				commit('setDownloadTransactionsFile', axios_response.body.url);
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async deleteExportTransactionsFile({commit}, payload) {
			
			await this.$hefesto.$post(`/orders/transactions/delete-file`,{ url_file: payload.url_file }).then( (axios_response) => {
				
				commit('setDownloadTransactionsFile', "");
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		}
	}
}