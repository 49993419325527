import { randomNumber, validUploadFile } 	from '../../constants/config';

export default {
	state: () => ({
		user				: null,
		isLogin				: false,
		change_password		: null,
		lead				: null,
		customer			: null,
		customerLanding		: null,
		profile_image		: null,
		customerLeadAgent	: null,
		level				: null,
		statement			: null,
		customerAgent		: null,
		customerAddresses	: [],
		validCustomer		: null,
		affiliate			: null,
		available_affiliate	: false,
		affiliate_commissions: []
	}),
	mutations: {
		setUser(state, payload) {
			state.user = payload
		},
		setChangePassword(state, payload) {
			state.change_password = payload
		},
		setLogin(state, payload) {
			state.isLogin = payload
		},
		setLead(state, payload) {
			state.lead = payload
		},
		setCustomer(state, payload) {
			state.customer = payload
		},
		setCustomerLanding(state, payload) {
			state.customerLanding = payload
		},
		setCustomerLeadAgent(state, payload) {
			state.customerLeadAgent = payload
		},
		setProfileImage(state, payload){
			state.profile_image = payload;
		},
		setLevel(state, payload) {
			state.level = payload
		},
		setStatement(state, payload) {
			state.statement = payload
		},
		setCustomerAgent(state, payload){
			state.customerAgent = payload;
		},
		setCustomerAddresses(state, payload) {
			state.customerAddresses = payload
		},
		setValidCustomer(state, payload) {
			state.validCustomer = payload;
		},
		setAffiliate(state, payload) {
			state.affiliate = payload
		},
		setAvailableAffiliate(state, payload) {
			state.available_affiliate = payload
		},
		setAffiliateTransactions(state, payload) {
			state.affiliate_commissions = payload
		}
	},
	getters: {
		getterUser					: state => state.user,
		getterChangePassword		: state => state.change_password,
		getterLogin					: state => state.isLogin,
		getterLead					: state => state.lead,
		getterCustomer				: state => state.customer,
		getterCustomerLanding		: state => state.customerLanding,
		getterCustomerLeadAgent		: state => state.customerLeadAgent,
		getterProfileImage			: state => state.profile_image,
		getterLevel					: state => state.level,
		getterSatement      		: state => state.statement,
		getterCustomerAgent     	: state => state.customerAgent,
		getterCustomerAddresses		: state => state.customerAddresses,
		getterValidCustomer			: state => state.validCustomer,
		getterAffiliate				: state => state.affiliate,
		getterAvailableAffiliate	: state => state.available_affiliate,
		getterAffiliateTransactions	: state => state.affiliate_commissions
	},
	actions: {
		// =================================================================
		//  CUSTOMER ACTIONS
		// =================================================================
		async getCustomer({commit}, payload) {
			
            let request_body ={
                query: { id_email: payload.id_email },
                fields: {
					_id: 1,
					first_name: 1,
					last_name: 1,
					email: 1,
					agent: 1,
					agent_email: 1,
					phone: 1,
					profile_image: 1,
					addresses: 1,
					first_order: 1,
					valid_moa: 1,
				},
                options: {
                    populate: null,
                    lean: true
                }
            };
			await this.$hefesto.$post('/customers/find', request_body).then( (axios_response) => {
				
				if( payload.origin === "landing" ){
					
					commit('setCustomerLanding', axios_response.body);
				}
				else{
					
					commit('setCustomer', axios_response.body);
					commit('setCustomerAddresses', axios_response.body?.addresses || [] );
					let data_user = {
						id				: this.$cookies.get('user-store').id,
						name			: `${ axios_response.body.first_name } ${ axios_response.body.last_name }`,
						email			: axios_response.body.email,
						profile_image	: axios_response.body.profile_image != null ? `${ axios_response.body.profile_image }?v=${ randomNumber() }` : null
					};
					this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
					commit('setUser', data_user);
				}
				commit('setProfileImage', axios_response.body.profile_image != null ? `${ axios_response.body.profile_image }?v=${ randomNumber() }` : null );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getCustomerAgent({ commit }, payload){
			
			await this.$hefesto.$get(`/customers/agent`).then( (axios_response) => {
				
                axios_response.body.photo = axios_response.body.photo != '' ? `${ this.$config.agent.baseURL.replace('/api', '') }/${ axios_response.body.photo.replace('public/', '') }` : null;
				commit('setCustomerAgent', axios_response.body);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async validCustomer({commit}, payload) {
			
			await this.$hefesto.$post('/customers/valid-email', payload).then( (axios_response) => {
				
				commit('setValidCustomer', axios_response);
			}).catch( (axios_error) => {
				
				commit('setValidCustomer', axios_error.response.data);
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async updateCustomer({commit}, payload) {
			
			await this.$hefesto.$put('/customers/update', payload ).then( (axios_response) => {
				
				axios_response.body.user.profile_image = axios_response.body.user.profile_image != null ? `${ axios_response.body.user.profile_image }?v=${ randomNumber() }` : null;
				commit('setCustomer', axios_response.body.customer);
				commit('setUser', axios_response.body.user);

				this.$cookies.set('user-store', JSON.stringify( axios_response.body.user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });

				commit('setNotification', { content: { type: 'request' }, type: 'success', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getLevelCustomer({ commit }) {
			
			await this.$hefesto.$get(`/app-agent/level/level-customer`).then( (axios_response) => {
				
				commit('setLevel', axios_response.body.result);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async updateUser({ commit }, payload) {
			
			commit('setUser', payload);
		},
		async getAffiliate({ commit }, payload) {
			
			await this.$hefesto.$post(`/affiliates/find?storefront=affiliates`).then( (axios_response) => {
				
				axios_response.body.url_code = `${ this.$config.storefront_url }/affiliates?code_af=${ axios_response.body.code }`;
				console.log( axios_response.body );
				commit('setAffiliate', axios_response.body);
				commit('setAvailableAffiliate', axios_response.body.status === 'active' );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async updateAffiliate({ commit }, payload) {
			
			await this.$hefesto.$put(`/affiliates/update?storefront=affiliates`, payload).then( (axios_response) => {
				
				commit('setAvailableAffiliate', axios_response.body.status === 'active' );
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async getAffiliateTransactions({ commit }, payload) {

			await this.$hefesto.$post(`/affiliates/list-transactions?storefront=affiliates`, payload).then( (axios_response) => {
				
				commit('setAffiliateTransactions', axios_response.body.transactions);
			}).catch( (axios_error) => {
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		// =================================================================
		//  LEAD ACTIONS
		// =================================================================
		async createLead({ commit }, payload) {
			
			await this.$axios.$get('https://api.ipify.org/?format=json').then( async (axios_response) => {
				
				payload.data.client_ip = axios_response.ip;
				await this.$hefesto.$post(`/app-agent/lead/create`, payload).then( (axios_response) => {
					
					commit('setLead', { valid: true, type: "create", data: axios_response.body });
				}).catch( (axios_error) => {
					
					commit('setLead', { valid: false, type: "create", data: null });
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			}).catch( async (axios_error) => {
				
				await this.$hefesto.$post(`/app-agent/lead/create`, payload).then( (axios_response) => {
					
					const { data } = axios_response;
					commit('setLead', { valid: true, type: "create", data: data });
				}).catch( (axios_error) => {
					
					commit('setLead', { valid: false, type: "create", data: null });
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			});
		},
		async updateLead({ commit }, payload) {
			
			await this.$hefesto.$put(`/app-agent/lead/update`, payload).then( (axios_response) => {
				
				commit('setLead', { valid: true, type: "update", data: axios_response.body });
			}).catch( (axios_error) => {
				
				commit('setLead', { valid: false, type: "update", data: null });
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		async saveCallLead({ commit }, payload) {
			
			let data_lead = {
				google_add_id	: this.$cookies.get('gclid') 		? this.$cookies.get('gclid').value 	: null,
				origin_add		: this.$cookies.get('origin_add') 	? this.$cookies.get('origin_add') 	: null,
				utms			: {
					utmSource	: this.$cookies.get('utmSource') 	? this.$cookies.get('utmSource')	: null,
					utmMedium	: this.$cookies.get('utmMedium') 	? this.$cookies.get('utmMedium') 	: null,
					utmCampaign	: this.$cookies.get('utmCampaign') 	? this.$cookies.get('utmCampaign') 	: null,
					utmTerm		: this.$cookies.get('utmTerm') 		? this.$cookies.get('utmTerm') 		: null
				},
				client_ip		: null
			};
			await this.$axios.$get('https://api.ipify.org/?format=json').then( async (axios_response) => {
				
				data_lead.client_ip = axios_response.ip;
				await this.$hefesto.$post( '/users/call-lead/create', data_lead ).then( (axios_response) => {}).catch( (axios_response) => {});
			}).catch( async (axios_error) => {
				
				await this.$hefesto.$post( '/users/call-lead/create', data_lead ).then( (axios_response) => {}).catch( (axios_response) => {});
			});
		},
		async registerCallLead({ commit }, payload){
			
		},
		// =================================================================
		//  STATEMENT ACTIONS
		// =================================================================
		async getStatementByCustomer({ commit }, payload){
			
			let exist_id = payload ? `?customer=${ payload.id }`: '';
			await this.$hefesto.$get(`/app-billing/statement/get-by-customer${ exist_id }`).then( (axios_response) => {
				
				commit('setStatement', axios_response.body);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			})
		},
		// =================================================================
		//  USER ACTIONS
		// =================================================================
		updateLoginUser({ commit }, payload) {
			
			if( ( payload.token_login || payload.custom_checkout === true ) && payload.data_user && payload.data_customer ){
				
				commit('setUser', payload.data_user );
				if( payload.data_customer.addresses ){
					commit('setCustomerAddresses', payload.data_customer.addresses );
					delete payload.data_customer.addresses;
				}
				commit('setCustomer', payload.data_customer );
				this.$cookies.set('user-store'		, JSON.stringify( payload.data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				this.$cookies.set('customer-store'	, JSON.stringify( payload.data_customer ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				commit('setLogin', true);
				commit('setProfileImage', payload.data_user.profile_image != null ? `${ payload.data_user.profile_image?.split('?')[0] }?v=${ randomNumber() }` : null );

				if( payload.token_login ){
					this.$cookies.set('token-app', payload.token_login, { path: '/', maxAge: 60 * 60 * 24 * 7 });
				}
			}
			else if( ['/account/dashboard'].find( (item) => payload.current_route.indexOf(item) >= 0 ) ){
				
				this.$router.push('/account/login');
			}
		},
		async loginUser({commit, state}, payload) {
			
			await this.$hefesto.$post(`/users/login`, payload).then( (axios_response) => { 
				
				axios_response.body.user.profile_image = axios_response.body.user.profile_image != null ? `${ axios_response.body.user.profile_image }?v=${ randomNumber() }` : null;

				commit('setProfileImage', axios_response.body.user.profile_image );
				
				this.$cookies.set('token-app'	, axios_response.body.user.token_login, { path: '/', maxAge: 60 * 60 * 24 * 7 });
				let data_user = {...axios_response.body.user };
				let data_customer = {
					_id				: axios_response.body.customer._id,
					first_name		: axios_response.body.customer.first_name,
					last_name		: axios_response.body.customer.last_name,
					email			: axios_response.body.customer.email,
					agent			: axios_response.body.customer.agent,
					agent_email		: axios_response.body.customer.agent_email,
					phone			: axios_response.body.customer.phone,
					profile_image	: axios_response.body.customer.profile_image,
					is_affiliate	: axios_response.body.customer.is_affiliate
				};
				let customer_addresses = axios_response.body.customer.addresses;
				delete data_user.token_login;

				this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				this.$cookies.set('customer-store'	, JSON.stringify( data_customer ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
				
				this.$cookies.remove("gclid");
				this.$cookies.remove("origin_add"); 
				this.$cookies.remove("utmSource"); 
				this.$cookies.remove("utmMedium");
				this.$cookies.remove("utmCampaign");
				this.$cookies.remove("utmTerm");
				
				commit('setUser', data_user );
				commit('setCustomer', data_customer );
				commit('setCustomerAddresses', customer_addresses );

				commit('products/setCart', axios_response.body.cart, { root: true });
				commit('products/setCartSaveLater', axios_response.body.save_later, { root: true });
				commit('produts/setCoupon', axios_response.body.coupon, { root: true });
				commit('setLogin', true);
				
				if( [undefined, null, "null"].includes( this.$cookies.get('app-version') ) ){

					this.$cookies.set('app-version', axios_response.app_version, { path: '/', maxAge: 60 * 60 * 24 * 7 });
				}
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: `Hello ${ axios_response.body.user.name }`, message: "Welcome to Cata1og" } });
				
			}).catch((axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async logoutUser({commit, state}, payload) {
			
			this.$cookies.remove('token-app');
			this.$cookies.remove('user-store');
			this.$cookies.remove('customer-store');
			
			commit('setUser', null);
			commit('setCart', { products: [], details: null });
			commit('setCartSaveLater', { products: [], details: null });
			commit('setCoupon', { name: null });
			
			commit('setLogin', false);
		},
		async updateCustomerPassword({commit}, payload) {
			
			await this.$hefesto.$put(`/users/update-password`,payload).then( (axios_response) => {
				
				const { title, message } = axios_response;
				
				commit('setNotification', { content: { type: 'request' }, type: 'success', data: { title: title, message: message } });
				
				commit('setChangePassword', { data: { message: "Successfully Updated Password" }})    
			}).catch((axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async changeProfileImage({ commit }, payload){
			
			let result = validUploadFile( payload.file, payload.input_name, payload.max_size, payload.old_file_path, payload.extra_data );
			
			if( result.success ){
				
				await this.$hefesto.$post('/customers/add-profile-image', result.data ).then( (axios_response) => {
					
					let data_user = {
						id				: this.$cookies.get('user-store').id,
						name			: this.$cookies.get('user-store').name,
						email			: this.$cookies.get('user-store').email,
						profile_image	: axios_response.body.url
					};
					this.$cookies.set('user-store'	, JSON.stringify( data_user ), { path: '/', maxAge: 60 * 60 * 24 * 7 });
					commit('setUser', data_user);
					
					commit('setProfileImage', axios_response.body.url != null ? `${ axios_response.body.url }?v=${ randomNumber() }` : null);
				}).catch( ( axios_error ) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			}
			else{
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: result.title, message: result.message, app_version: this.$cookies.get('app-version') } });
			}
		},
		async findCustomerLeadAgent({ commit }, payload) {
			
			await this.$hefesto.$post('/users/find-agent', { email: payload.email } ).then( (axios_response) => {
				
				commit('setCustomerLeadAgent', axios_response.body);
			}).catch( ( axios_error ) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: { title: axios_error.response.data.title, message: axios_error.response.data.message } });
			});
		},
		// =================================================================
		//  ADDRESS ACTIONS
		// =================================================================
		async listCustomerAddress({commit}, payload) {
			
			await this.$hefesto.$post('/customers/address/list').then( (axios_response) => {
				
				commit('setCustomerAddresses', axios_response.body || []);
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async saveCustomerAddress({commit}, payload) {
			
			await this.$hefesto.$put('/customers/address/save', payload ).then( (axios_response) => {
				
				commit('setCustomerAddresses', axios_response.body || []);
				commit('setNotification', { content: { type: 'request' }, type: 'info', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
		async deleteCustomerAddress({commit}, payload) {
			
			await this.$hefesto.$put('/customers/address/delete', payload ).then( (axios_response) => {
				
				commit('setCustomerAddresses', axios_response.body || []);
				commit('setNotification', { content: { type: 'request' }, type: 'info', data: axios_response });
			}).catch( (axios_error) => {
				
				commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
			});
		},
	}
}
