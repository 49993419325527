import json_languages       from '../../data/languages.json';
import json_business_types  from '../../data/business-type.json';
import json_reviews         from '../../data/reviews.json';
import json_testimonials    from '../../data/testimonials.json';
import { arrayHelpers }     from '../../constants/config.js';

console.log( json_languages );
export default {
    state : () => ({
        db_countries		    : [],
        db_languages		    : json_languages,
        db_business_types	    : json_business_types,
        db_reviews              : json_reviews,
        db_testimonials         : json_testimonials,
        brands                  : [],
        list_cateogry_articles  : null,
        item_article            : null,
        agent                   : null
    }),
    mutations: {
        setBrands(state, payload) {
            state.brands = payload
        },
        setListCategoryArticles(state, payload) {
            state.list_cateogry_articles = payload
        },
        setItemArticle(state, payload) {
            state.item_article = payload
        },
        setCountries(state, payload) {
            state.db_countries = payload;
        },
        setReviews(state, payload) {
            state.db_reviews = payload;
        },
        setTestimonials(state, payload) {
            state.db_testimonials = payload;
        },
        setAgent(state, payload) {
            state.agent = payload;
        },
        setLeadLanguages(state, payload) {
            state.db_languages = payload;
        },
    },
    getters: {
        getterBrands                : state => state.brands,
        getterListCategoryArticles  : state => state.list_cateogry_articles,
        getterItemArticle           : state => state.item_article,
        getterCountries			    : state => state.db_countries,
        getterLeadLanguages			: state => state.db_languages,
        getterBusinessTypes         : state => state.db_business_types,
        getterReviews               : state => state.db_reviews,
        getterTestimonials          : state => state.db_testimonials,
        getterAgent                 : state => state.agent

    },
    actions: {
        // =================================================================
        //  GENERAL ACTIONS
        // =================================================================
        
        async getCountries({commit}, payload) {
            
            await this.$hefesto_file.$get('/documents/json/store/countries.json').then( (axios_response) => {
                
                commit('setCountries', axios_response);
            });
        },
        async getSalesAgent({commit}, payload) {
            
            await this.$hefesto.$post(`/agents/find`, payload).then( (axios_response) => {
                
                axios_response.body.photo = axios_response.body.photo != '' ? `${ this.$config.agent.baseURL.replace('/api', '') }/${ axios_response.body.photo.replace('public/', '') }` : null;
                commit('setAgent', axios_response.body);
            });
        },
        async getBrands({commit}, payload) {
            
            await this.$hefesto.$post(`/brands/list-front`).then( (axios_response) => {
                
                commit('setBrands', axios_response.body);
            });
        },
        // =================================================================
        //  BLOG ACTIONS
        // =================================================================
        async getListCategoryArticless({commit, state}, payload) {
            
            await this.$hefesto.$get(`/front/blog/category/list`).then( async (axios_response) => { 

                commit('setListCategoryArticles', axios_response.body.reduce( (previous_item, current_item) => {
                    
                    let desktop_articles = arrayHelpers.chunkArray( JSON.parse( JSON.stringify( current_item.articles ) ), 3 );
                    let mobile_articles = JSON.parse( JSON.stringify( current_item.articles ) );
                    current_item.articles = {
                        desktop: desktop_articles,
                        mobile: mobile_articles
                    };
                    previous_item.push( current_item );
                    return previous_item;
                }, []) );
            }).catch((axios_error) => {
                
                commit('setListCategoryArticles', []);
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        },
        async getItemBlog({commit, state}, payload) {
            
            await this.$hefesto.$get(`/front/blog/article/find/${ payload.handle }`).then( async (axios_response) => { 

                commit( 'setItemArticle', axios_response.body );
                
            }).catch((axios_error) => {
                
                commit('setItemArticle', { message: "not found" });
                commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
            });
        }
    }
}
