import Vue from 'vue'
import ZoomOnHover from 'vue-zoom-on-hover';
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import VuePaycard from 'vue-paycard';
import VueScrollTo from 'vue-scrollto'
import Toast from 'vue-toastification';
import VueQRCodeComponent from 'vue-qrcode-component';
import VueClipboard from 'vue-clipboard2';
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.use(VueAwesomeSwiper)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(Toast, { transition: 'Vue-Toastification__fade', maxToasts: 20, newestOnTop: true });
Vue.use(VuePaycard);
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('v-select', vSelect);
Vue.use(ZoomOnHover);
Vue.use(VueScrollTo);