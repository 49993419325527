import Vue  		from 'vue'
import Vuex 		from 'vuex'

import customers    from './modules/customers.js';
import products   	from './modules/products.js';
import sales       	from './modules/sales.js';
import store		from './modules/store.js';

Vue.use(Vuex);
const createStore = () => {
	return new Vuex.Store({
		state: {
			basic_data      : null,
			navigations		: null,
			slideshows		: null,
			languages		: null,
			payment_methods	: null,
			charge_methods	: null,
			pages			: null,
			metrics			: null,
			app_version     : null,
			notification   	: null,
		},
		getters: {
			getterBasicData     : state => state.basic_data,
			getterNavigations	: state => state.navigations,
			getterSlideshows	: state => state.slideshows,
			getterLanguages		: state => state.languages,
			getterPaymentMethods: state => state.payment_methods,
			getterChargeMethods	: state => state.charge_methods,
			getterPages			: state => state.pages,
			getterHomeMetrics	: state => state.metrics,
			getterAppVersion    : state => state.app_version,
			getterNotification	: state => state.notification
		},
		mutations: {
			setBasicData(state, payload) {
				state.basic_data = payload
			},
			setNavigations(state, payload) {
				state.navigations = payload
			},
			setSlideshows(state, payload) {
				state.slideshows = payload
			},
			setLanguages(state, payload) {
				state.languages = payload
			},
			setPaymentMethods(state, payload) {
				state.payment_methods = payload
			},
			setChargeMethods(state, payload) {
				state.charge_methods = payload
			},
			setPages(state, payload) {
				state.pages = payload
			},
			setHomeMetrics(state, payload) {
				state.metrics = payload	
			},
			setAppVersion(state, payload) {
				state.app_version = payload
			},
			setNotification(state, payload) {
				state.notification = payload;
			},
		},
		actions: {
			addNotification({ commit }, payload) {
				
				commit('setNotification', payload);
			},
			async nuxtServerInit({ commit }, { $axios }) {
				
				await $axios.$get(`/general-settings/find`).then( (axios_response) => { 

					commit('setBasicData', axios_response.body.config_basic_data);
					commit('setNavigations', axios_response.body.navigations);
					commit('setSlideshows', axios_response.body.slideshows);
					commit('setLanguages', axios_response.body.languages);
					commit('setPaymentMethods', axios_response.body.payment_methods);
					commit('setChargeMethods', axios_response.body.charge_methods);
					commit('setPages', axios_response.body.pages);
					commit('setHomeMetrics', axios_response.body.metrics);

				}).catch((axios_error) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			},
			async updateCompanyData({commit}, payload) {
				
				commit('setBasicData', payload);
			},
			async getGeneralSettings({commit}, payload) {

				await this.$hefesto.$get(`/general-settings/find`).then( (axios_response) => { 

					commit('setBasicData', axios_response.body.config_basic_data);
					commit('setNavigations', axios_response.body.navigations);
					commit('setSlideshows', axios_response.body.slideshows);
					commit('setLanguages', axios_response.body.languages);
					commit('setPaymentMethods', axios_response.body.payment_methods);
					commit('setChargeMethods', axios_response.body.charge_methods);
					commit('setPages', axios_response.body.pages);
					commit('setHomeMetrics', axios_response.body.metrics);
					
				}).catch((axios_error) => {
					
					commit('setNotification', { content: { type: 'request' }, type: 'error', data: axios_error.response.data });
				});
			},
		},
		modules: {
			customers,
			products,
			sales,
			store
		}
	});
}
export default createStore
